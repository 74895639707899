var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.path !== '/livevideo')?_c('div',[(_vm.isLogin)?_c('div',{staticClass:"md-nav-welcome"},[_c('p',[_vm._v(" 欢迎 "+_vm._s(_vm.userInfo ? _vm.userInfo?.dept?.deptName || _vm.userInfo.deptName : _vm.deptNameinfo?_vm.deptNameinfo:_vm.seesiondeptNameinfo)+" ")])]):_vm._e(),(!_vm.$store.state.specialRouters.includes(_vm.$route.path))?_c('header',{staticClass:"md-shared-header",class:[_vm.$store.state.fixed ? 'md-shared-header-fixed' : ''],style:({ top: _vm.isLogin ? '30px' : '0px' }),on:{"click":function($event){_vm.isShowSearchBox = false}}},[_c('div',{staticClass:"md-nav-box"},[_c('div',{staticClass:"md-nav-panel"},[_c('div',{staticStyle:{"margin-right":"30px","display":"flex","align-items":"center"}},[(_vm.userInfo && _vm.userInfo.dept.deptId === 269)?_c('img',{attrs:{"src":require("@/assets/img/icon.png"),"alt":""},on:{"click":function($event){return _vm.handleNav({ path: '/home' })}}}):_c('img',{attrs:{"src":require("@/assets/img/icon.png"),"alt":""},on:{"click":function($event){return _vm.handleNav({ path: '/home' })}}}),(
              (_vm.userInfo &&
                ((_vm.userInfo.dept &&
                  _vm.userInfo.dept.deptName === '吉林艺术学院') ||
                  (_vm.userInfo.deptName &&
                    _vm.userInfo.deptName === '吉林艺术学院'))) ||
              (_vm.deptNameinfo && _vm.deptNameinfo == '吉林艺术学院')|| (_vm.seesiondeptNameinfo && _vm.seesiondeptNameinfo == '吉林艺术学院')
            )?_c('span',{staticClass:"span-title"},[_vm._v("多媒体学习及学科服务平台 ")]):_vm._e(),(
              (_vm.userInfo &&
                ((_vm.userInfo.dept &&
                  _vm.userInfo.dept.deptName === '吉林工商学院') ||
                  (_vm.userInfo.deptName &&
                    _vm.userInfo.deptName === '吉林工商学院'))) ||
              (_vm.deptNameinfo && _vm.deptNameinfo == '吉林工商学院')||    (_vm.seesiondeptNameinfo && _vm.seesiondeptNameinfo == '吉林工商学院')
            )?_c('div',{staticClass:"span-title"},[_c('div',[_vm._v("多媒体数据库")]),_c('div',{staticStyle:{"font-size":"12px","margin-top":"5px"}},[_vm._v(" (高校教育版v1.0) ")])]):_c('span',[_vm._v(" 外语学习平台")])]),_c('ul',{staticClass:"class-list"},[_vm._l((_vm.navList),function(item,index){return _c('li',{key:index,class:['class-item', { download: item.name == '下载和关注' }],on:{"click":function($event){return _vm.handleNav(item)}}},[_c('span',{class:_vm.$route.path === item.path ? 'active' : 'item-span'},[_vm._v(_vm._s(item.name))]),(item.child)?_c('div',{staticClass:"child"},[_c('ul',_vm._l((item.child),function(child,i){return _c('li',{key:i,staticClass:"child-li",on:{"click":function($event){return _vm.handleNav(child)}}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","placement":"left","popper-class":"tooltip-header"}},[_c('img',{staticStyle:{"width":"90px","height":"90px"},attrs:{"slot":"content","src":child.img,"alt":""},slot:"content"}),_c('div',[_c('img',{staticStyle:{"width":"20px","height":"20px","margin-right":"5px"},attrs:{"src":child.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(child.name))])])])],1)}),0)]):_vm._e()])}),(
              _vm.userInfo &&
              ((_vm.userInfo.dept && _vm.userInfo.dept.deptName === '吉林艺术学院') ||
                (_vm.userInfo.deptName && _vm.userInfo.deptName === '吉林艺术学院'))
            )?_c('li',{staticClass:"class-item",on:{"click":_vm.goPage}},[_c('span',{staticClass:"item-span"},[_vm._v("学科资源")])]):_vm._e()],2)]),_c('div',{staticClass:"nav-left"},[_c('MdNavClassComponent')],1)])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }